export const frTranslations = {
  header: {
    login: 'Se connecter',
  },
  language: {
    french: 'Français',
    english: 'Anglais',
  },
  account: {
    title: 'Mon profil',
    save: 'Enregistrer',
  },
  signIn: {
    title: "S'identifier",
    email: 'Adresse e-mail',
    emailPlaceholder: 'Votre adresse e-mail',
    password: 'Mot de passe',
    login: "S'identifier",
    incorrectPassword: 'E-mail ou mot de passe incorrect',
    forbidden: "Votre compte est désactivé, pour plus d'informations, veuillez",
    forbiddenContact: 'nous contacter',
    forgotPassword: 'Mot de passe oublié ?',
    newAccount: "Vous n'avez pas encore de compte ?",
    newAccountLink: 'Créer un compte',
  },
  signUp: {
    title: 'Créer mon compte',
    isRetailer: 'Je suis revendeur/Professionnel de l’hygiène en France (PCO)',
    socialReason: 'Raison sociale',
    serialNumber: 'Numéro de série',
    findSerialNumber: 'Comment trouver mon numéro de série ?',
    lastName: 'Nom',
    lastNamePlaceholder: 'Votre nom',
    firstName: 'Prénom',
    firstNamePlaceholder: 'Votre prénom',
    addressFirstLine: 'Adresse',
    addressSecondLine: 'Complément',
    addressPostalCode: 'Code postal',
    addressCity: 'Ville',
    addressCountry: 'Pays',
    email: 'E-mail',
    emailPlaceholder: 'Votre adresse e-mail',
    phoneNumber: 'Téléphone',
    password: 'Mot de passe',
    confirmPassword: 'Confirmation de mot de passe',
    confirmPasswordPlaceholder: 'Confirmez votre mot de passe',
    accept: "J'ai lu et j'accepte les",
    acceptAnd: 'et la ',
    cgu: "conditions générales d'utilisation",
    privacy: 'politique de confidentialité',
    acceptError: "Vous devez accepter les conditions générales d'utilisation",
    create: 'Créer mon compte',
  },
  shared: {
    email: 'E-mail',
    password: 'Mot de passe',
    passwordPlaceholder: 'Votre mot de passe',
    confirmPassword: 'Confirmation de mot de passe',
    confirmPasswordPlaceholder: 'Confirmez votre mot de passe',
  },
  activate: {
    title: 'Activation de compte',
    email: 'E-mail',
    password: 'Mot de passe',
    passwordPlaceholder: 'Votre mot de passe',
    confirmPassword: 'Confirmation de mot de passe',
    confirmPasswordPlaceholder: 'Confirmez votre mot de passe',
    button: 'Activer',
    error: {
      conflict: 'Ce compte est déjà activé',
    },
  },
  resetPassword: {
    title: 'Réinitialiser le mot de passe',
    button: 'Réinitialiser',
  },
  forgotPassword: {
    title: 'Mot de passe oublié',
    email: 'Adresse e-mail',
    emailPlaceholder: 'Votre adresse e-mail',
    text: "Merci de renseigner l'adresse e-mail associée à votre compte. Nous vous enverrons les instructions par mail afin de modifier votre mot de passe.",
    send: 'Envoyer',
    cancel: 'Annuler',
    success: 'Un mail a été envoyé à cette adresse avec la procédure à suivre',
    error: {
      notFound: 'Aucun utilisateur trouvé pour cette adresse e-mail',
    },
  },
  error: {
    message: {
      default: 'Une erreur technique est survenue',
      unauthorized: 'Email ou mot de passe incorrect',
      notFound: 'Numéro de série incorrect',
      conflict: 'Compte existant',
      confirmPasswordError: 'Les mots de passe ne correspondent pas',
    },
    serial: {
      notFound: 'Numéro invalide',
      forbidden: 'La création du ticket de panne doit se faire par votre revendeur. Veuillez le contacter.',
    },
    page: {
      seoTitle: 'Erreur',
      title: "Cette page n'existe pas",
      description: 'Il n’y a rien ici…Repartir sur la page d’accueil ?',
      home: 'Accueil',
    },
  },
  success: {
    message: {
      default: 'Vos informations ont bien été modifiées',
    },
  },
  user: {
    menu: {
      profile: 'Mon profil',
      logout: 'Se déconnecter',
    },
    modal: {
      title: "Changer l'e-mail",
      oldEmail: 'Ancien e-mail',
      email: 'Nouvel e-mail',
      emailPlaceholder: 'Votre nouvel e-mail',
      cancel: 'Annuler',
      save: 'Enregistrer',
    },
  },
  contact: {
    companyName: 'Raison sociale',
    lastName: 'Nom',
    lastNamePlaceholder: 'Votre nom',
    firstName: 'Prénom',
    firstNamePlaceholder: 'Votre prénom',
    email: 'Adresse e-mail',
    emailPlaceholder: 'Votre adresse e-mail',
    phoneNumber: 'Numéro de téléphone',
    phoneNumberPlaceholder: 'Votre numéro de téléphone',
    subject: 'Objet',
    subjectPlaceholder: 'Objet de votre demande',
    message: 'Message',
    messagePlaceholder: 'Votre message',
    required: 'Champs requis',
    send: 'Envoyer',
    title: 'Demande envoyée',
    success: {
      title: 'Votre message a bien été envoyé',
      subtitle:
        "Nous vous confirmons l'envoi de votre message à nos équipes support. Nous vous répondrons par mail dans les meilleurs délais.",
      link: 'Accueil',
    },
  },
  search: {
    placeholder: 'Rechercher un diagnostic, un produit, un document...',
    title: 'Votre recherche',
    empty: 'Aucun résultat',
    homeTitle: "Page d'accueil",
  },
  diagnostics: {
    resolved: {
      title: 'Merci d’avoir réalisé votre diagnostic en ligne !',
      subtitle: 'Merci pour votre temps !',
      label: 'Votre avis nous intéresse',
      text: 'Afin de mieux vous aider, laissez nous une note et un commentaire. Plus vous nous fournirez de détails, plus nous serons en mesure de mieux vous accompagner',
      comment: 'Avez-vous des commentaires à nous partager ? (facultatif)',
      commentPlaceholder: 'Message',
      send: 'Envoyer',
      error: {
        badRequest: 'Vous devez mettre une note supérieure à 0.',
      },
    },
    success: {
      title: 'Commentaire envoyé',
      subtitle: 'Votre note a bien été envoyée',
      text: "Merci d'avoir pris le temps de nous faire ce retour, vos commentaires sont précieux",
      home: 'Accueil',
    },
  },
  tickets: {
    title: 'Ticket de panne',
    signIn: 'Connectez-vous pour nous faire parvenir votre demande de prise en charge SAV',
    subtitle: "Quel est l'appareil concerné ?",
    serial: "Veuillez préciser le numéro de série de l'appareil concerné par votre panne",
    serialPlaceholder: 'Numéro de série',
    next: 'Suivant',
    home: 'Accueil',
    contact: 'Nous contacter',
    create: {
      title: "Création d'un ticket SAV",
      text: 'Afin de finaliser la création de votre ticket SAV, veuillez vérifier vos informations :',
      serialNumber: 'Numéro de série',
      diagnostic: 'Nom du diagnostic',
      companyName: 'Raison sociale',
      lastName: 'Nom',
      lastNamePlaceholder: 'Votre nom',
      firstName: 'Prénom',
      firstNamePlaceholder: 'Votre prénom',
      email: 'E-mail',
      emailPlaceholder: 'Votre adresse e-mail',
      phoneNumber: 'Téléphone',
      phoneNumberPlaceholder: 'Votre numéro de téléphone',
      address: 'Adresse',
      addressPlaceholder: 'Votre adresse postale',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      comment: 'Commentaire',
      commentPlaceholder: 'Résumez brièvement votre panne ici',
      required: '* Champs requis',
      button: 'Créer un ticket SAV',
      remaining: 'Nombre de caractères restants : ',
    },
    success: {
      title: 'Votre demande a bien été déposée auprès de nos équipes SAV',
      subtitle:
        'Nous vous confirmons la création de votre ticket SAV. Un mail contenant un lien de suivi de votre demande vous a été envoyé.',
      link: 'Accueil',
    },
    detail: {
      title: 'Suivi de votre ticket',
      subtitle: 'Votre ticket a bien été reçu par nos services',
      send: 'Envoyé le',
      text: 'Vous avez déposé un ticket pour la panne',
      serialNumber: 'Numéro de série :',
      status: {
        ouv: 'Ouvert',
        matr: 'Matériel réceptionné',
        enco: 'Traitement SAV en cours',
        mate: 'Matériel expédié',
        ferm: 'Ticket SAV clôturé',
      },
    },
  },
  select: {
    add: 'Ajouter',
  },
};
